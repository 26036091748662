/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Lato-Regular-LatinExt.woff2') format('woff2');
    unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Lato-Regular-Latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
    margin: 0;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body .p-component {
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.paginatorLeftDiv {
    display: flex;
    font-size: 15px;
    margin-left: 40px;
    margin-right: -40px;
    align-items: baseline;
}

.paginatorLeftDiv > span {
    margin-top: 5px;
}

.pageSizeSelectInput {
    width: 60px;
    height: 30px;
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.TableColBadge {
    width: 100%;
}

.dim14clearbutton {
    width: 100px;
    float: right;
    margin-top: 5px;
}

.dim14querybutton {
    width: 100px;
    float: right;
}

.dim14CriteriaWrapperPanel {
    margin-bottom: 10px;
}

.dim14CriteriaPanel {
    width: 100%;
}

@media screen and (max-width: 1320px) {
    .dim14CriteriaPanel {
        text-align: center;
    }
}

.dim14textinput {
    height: 35px;
}

.Wrapper {
    min-height: 100vh;
    display: flex;
    overflow: auto;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Logo {
  margin-left: 10px;
  margin-right: 10px;
}

.Header {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
    border-bottom: 1px solid rgba(120,120,120,.1);
    justify-content: space-between;
}

.Header .bi {
    padding: 7px 8px;
    margin: 0 3px;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    color: #777;
    cursor: pointer;
}

.Header .bi:hover {
    background-color: #EEEEEE;
}

.Content {
    flex: 1 0 auto;
    padding: 20px;
    min-height: 100vh;

}

.Content .PageTitle {
    color: #5d5f61;
    padding: 10px 0 5px 10px;
    margin: 0 -11px 10px -11px;
    background-color: #f3f4f6
}

.Footer {
    width: 100%;
    color: #DDD;
    background-color: #636566;
}

.Footer .FooterLine {
    width: 100%;
    margin: 10px auto 0 auto;
    border-bottom: 2px solid rgba(255,255,255,.1);
}

.Footer .FooterContent {
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

.UserInfo {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 10px 15px;
}

.Menu{
  margin-right: auto;
  padding: 10px 20px;
}

.MenuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 5px;
  padding:5px 5px;
  text-decoration: none;
  color: #282c34;
}

.MenuItem:link {
  text-decoration: none; 
}

.MenuItem.active {
  color: black;
  text-decoration: none; 
  background-color: #f3f3f3;
  border-radius: 5px;
 }

.MenuItem:hover{
  background-color: whitesmoke;
  border-radius: 5px;
}

.MenuItem:hover {
  cursor: pointer;
  color: rgb(172, 12, 52); 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;
}

/*table, th, td {
  border: 1px solid black;
}*/

.MeldaApp .react-datepicker-popper {
  z-index : 10;
}

.p-datatable tr > td {
    font-size: 14px;
}

.p-datatable th {
    font-size: 13px;
}

.App.MeldaApp .p-datatable .p-datatable-thead > tr > th {
    padding-top: 0.25em;
    padding-right: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 0.25em;
}

.App.MeldaApp .p-datatable .p-datatable-tbody > tr > td {
    padding-top: 0.25em;
    padding-right: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 0.25em;
}

.display-flex {
    display: flex;
}

.display-grid {
    display: grid;
}

.cursor-pointer {
    cursor: pointer;
}

.MuiDialogActions-root {
    border-top: 1px solid #c8c8c8;
}

.MuiDialogTitle-root {
    font-family: Lato, Roboto, Arial, Helvetica, sans-serif;
    background-color: #f4f4f4;
    border-bottom: 1px solid #c8c8c8;
}

.dim14-dialog-body{
    min-width: 450px;
}

.findPatientPaginatorLeftDiv {
    display: flex;
    font-size: 15px;
    margin-left: 10px;
    margin-right: -200px;
}

.findPatientPaginatorLeftDiv > span {
    margin-top: 5px;
}

div[class^='col'], div[class*=' col']{
    padding: 5px;
}

.dim14-form-row-align .row {
    margin-bottom: -5px;
}

.no-padding {
    padding: 0px !important;
}

.no-margin {
    margin: 0px !important;
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.padding25 {
    padding: 25px;
}

.emr-dropdown {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    width: inherit !important;
    font-size: 5px !important;
}

.disable-pointer {
    pointer-events:none
}

.not-allowed {
    cursor: not-allowed;
    opacity: 0.5;
}

.circle {
    height: 20px;
    width: 20px;
    background-color: #999999;
    border-radius: 50%;
    display: inline-block;
}

.z-index-1400 {
    z-index: 1400 !important;
}

.signature-canvas{
    width:100%;
}

.disc-green {
    color:#7AAE00;
}

.disc-grey{
    color:#b6b6b6;
}

.disc-danger{
    color:red;
}

.moderate-risk{
    color: #ff8d2c;
}

.font-size-small {
    font-size: small;
}

.font-size-medium {
    font-size: medium;
}

.font-size-larger {
    font-size: larger;
}

.d14-selected-tab {
    border-style: dotted !important;
    border-color: palevioletred !important;
}

.link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.error-body {
    width:100vw;
    height: 100vh;
    background-color: #bfbfbf;
    position: absolute;
    background-image: url("/error-bg.png");

}

.hr-pending{
    background-color:#ffbc00;
    color:#fff;
    border-radius:2px;
    padding:4px;
    margin-top:4px;
    margin-bottom:4px;

}
.hr-submitted{
    background-color:#ED3A1D;
    color:#fff;
    border-radius:2px;
    padding:4px;
    margin-top:4px;
    margin-bottom:4px;

}
.hr-approved{
    background-color:#55BE2D;
    color:#fff;
    border-radius:2px;
    padding:4px;
    margin-top:4px;
    margin-bottom:4px;
}
.hr-rejected{
    background-color:#fff;
    color:#000;
    border-radius:2px;
    padding:4px;
    margin-top:4px;
    margin-bottom:4px;

}

body .p-dropdown-panel{
    z-index: 2000 !important;
}

.p-dropdown-label-py-0 .p-dropdown-label {
    padding-top: 0;
    padding-bottom: 0;
}

.react-tel-input .country-list .country{
    padding: 7px 9px 7px 44px;

}

 .react-tel-input .form-control {
    width:100%;
}

 .react-tel-input .country-list .flag {
    margin-top:9px;
}
 .search-box {
    width: calc(100% - 32px)
}

.calculated-table {
    width: calc(100% - 48px)
}

.table-sm {
    font-size: 14px;
}

@media (min-width: 1200px) {
    .Logo img {
        width:auto;
        height: 32px;
    }

}

@media (min-width: 1200px) {
    .Logo img {
        width:auto;
        height: 32px;
    }

}

.rbc-time-view  .rbc-time-content {
    overflow-x:auto;
    overflow-y: hidden;
}

.user-setup .dim14-calendar-container {
    width: 100%;
}

.user-setup .dim14-calendar-container .p-inputtext {
    height: 35px;
}

.user-setup .dim14-calendar-container .dim14-calendar-clear-icon {
    top: 12px;
}

.user-setup .dim14-calendar-container .dim14-calendar-clear-icon > *{
    fill: #848484;
}

.user-setup .p-calendar {
    display: block;
}